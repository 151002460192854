export const searchTable = {
    // 订单
    orders: {
        currentSelect: [{
            value: 'order_code',
            name: '订单编号',
            type: 'input',
        }, {
            value: 'customer_id',
            name: '经销商',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        }],
        seniorSelect: [ {
            name: '经销商',
            value: '',
            type: 'select',
            remote: true,//是否开启远程搜索功能
            list: 'customer_id'
        },{
            name: '时间',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        },],
        tooltipContent: '订单',
        tableHeader: [
            {
                label: '订单时间',
                value: 'order_date',
            },
            {
                label: '经销商',
                value: 'customer_name',
            },
            {
                label: '订单编号',
                value: 'order_code',
            },
            {
                label: '订单总额',
                value: 'order_sum',
            },
            {
                label: '发货状态',
                value: 'shipping_status',
            },
            {
                label: '发货单号',
                value: 'dispatch_code',
            }
        ]
    },
    // 产品
    products: {
        currentSelect: [{
            value: 'inventory_type',
            name: '产品类别',
            type: 'select'
        }, 
        // {
        //     value: 'inventory_id',
        //     name: '产品编码',
        //     type: 'input'
        // },
        {
            value: 'reagent_name',
            name: '产品名称',
            type: 'input'
        },
        ],
        tooltipContent: '产品',
        tableHeader: [
            {
                label: '产品名称',
                value: 'product_name',
            },
            {
                label: '产品编码',
                value: 'inventory_id',
            },
            {
                label: '产品类别',
                value: 'inventory_type',
            },
            {
                label: '规格',
                value: 'standard',
            },
            {
                label: '是否在售',
                value: 'is_sell',
            },
        ]
    },
    // 仪器
    instruments: {
        currentSelect: [{
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'platform_id',
            name: '平台',
            type: 'select'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // },
         {
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, {
            value: 'customer_id',
            name: '经销商',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        }, {
            value: 'salesman',
            name: '销售',
            type: 'input'
        }, {
            value: 'engineer',
            name: '工程师',
            type: 'input'
        }],
        seniorSelect: [
        {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        },{
            name: '平台',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'platform_id'
        }, {
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '市区',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'city_id'
        },{
            name: '是否穿刺机',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'is_stab'
        },
         {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            name: '是否厂内',
            value: '0',
            type: 'select',
            selectOption: [],
            list: 'is_factory'
        },
        {
            name: '销售',
            value: '',
            type: 'input',
            list: 'salesman'
        }, {
            name: '工程师',
            value: '',
            type: 'input',
            list: 'engineer'
        }, {
            name: '经销商',
            value: '',
            type: 'select',
            list: 'customer_id',
            remote: true,//是否开启远程搜索功能
        }],
        tooltipContent: '仪器',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '医院级别',
                value: 'level_name',
            },
            {
                label: '仪器位置',
                value: 'label',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150',
            },
            {
                label: '经销商',
                value: 'customer_name_extra',
            },
            {
                label: '售后人员',
                value: 'engineer_extra',
            },
            {
                label: '上位机版本号',
                value: 'version_upper_extra',
            },
            {
                label: '最后更新时间',
                value: 'last_use_time',
                width: '150'
            },
        ]
    },
    // 发货单
    dispatchs: {
        currentSelect: [{
            value: 'dispatch_code',
            name: '发货单号',
            type: 'input'
        }, {
            value: 'order_code',
            name: '订单编号',
            type: 'input'
        }, {
            value: 'customer_id',
            name: '经销商',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },],
        seniorSelect: [
            {
                name: '经销商',
                value: '',
                type: 'select',
                remote: true,//是否开启远程搜索功能
                list: 'customer_id'
            },
            {
                name: '批号',
                value: '',
                type: 'input',
                list: 'batch'
            }, {
                name: '时间',
                value: '',
                type: 'timeData',
                list: ['start_time', 'end_time']
            }],
        tooltipContent: '发货单',
        tableHeader: [
            {
                label: '创建时间',
                value: 'time_stamp',
            },
            {
                label: '经销商',
                value: 'customer_name',
            },
            {
                label: '订单编号',
                value: 'order_code',
            },
            {
                label: '发货单号',
                value: 'dispatch_code',
            },
            {
                label: '发货状态',
                value: 'shipping_status',
            },
            {
                label: '物流类型',
                value: 'shipping_type',
            },
            {
                label: '物流单号',
                value: 'shipping_code',
            },
        ]
    },
    // 试剂
    kits: {
        currentSelect: [
            {
                value: 'kit_id',
                name: 'UDI',
                type: 'input'
            }, {
                value: 'instrument_id',
                name: '机号',
                type: 'input'
            }, 
            {
                value: 'hospital_id',
                name: '医院',
                type: 'select',
                remote: true,//是否开启远程搜索功能
            }, 
            // {
            //     value: 'hospital_name',
            //     name: '医院',
            //     type: 'select',
            //     remote: true,//是否开启远程搜索功能
            // },
             {
                name: '平台',
                value: 'platform_unique',
                type: 'select',
            }, {
                value: 'batch',
                name: '批号',
                type: 'input'
            }, {
                value: 'customer_id',
                name: '经销商',
                type: 'select',
                remote: true,//是否开启远程搜索功能
            }, {
                value: 'testing_id',
                name: '检测项目',
                type: 'select'
            },],
        seniorSelect: [
            {
                name: '检测项目',
                value: '',
                type: 'select',
                selectOption: [],
                list: 'testing_id'
            },
            {
                name: '平台',
                value: '',
                type: 'select',
                selectOption: [],
                list: 'platform_unique'
            },
            {
                name: '经销商',
                value: '',
                type: 'select',
                list: 'customer_id',
                remote: true,//是否开启远程搜索功能
            },
            {
                name: '医院',
                value: '',
                type: 'select',
                list: 'hospital_id',
                remote: true,//是否开启远程搜索功能
            }, 
            {
                name: '机号',
                value: '',
                type: 'input',
                list: 'instrument_id'
            },
            // {
            //     name: '医院',
            //     value: '',
            //     type: 'select',
            //     list: 'hospital_name',
            //     remote: true,//是否开启远程搜索功能
            // }, 
            {
                name: '批号',
                value: '',
                type: 'input',
                list: 'batch'
            },
        ],
        tooltipContent: '试剂',
        tableHeader: [
            {
                label: 'UDI',
                value: 'kit_id',
                width: 250
            },
           
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '机号',
                value: 'instrument'
            },
            {
                label: '试剂名称',
                value: 'reagent_name_extra',
            },
            {
                label: '试剂批号',
                value: 'batch',
            },
            {
                label: '最后使用时间',
                value: 'last_used_date',
            },
            {
                label: '人份数',
                value: 'format_extra',
            },
            {
                label: '已使用人份数',
                value: 'cnt',
            },
            
            {
                label: '经销商',
                value: 'customer_name_extra',
            },
        ],
    },
    // 日志
    diarys: {
        currentSelect: [{
            value: 'recorder_name',
            name: '记录人',
            type: 'input'
        }, {
            value: 'diary_type',
            name: '日志类型',
            type: 'select'
        },],
        seniorSelect: [{
            name: '日志类型',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'diary_type'
        }, {
            name: '日志记录人',
            value: '',
            type: 'input',
            list: 'recorder_name'
        }, {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }
    ],
        tooltipContent: '日志',
        tableHeader: [
            {
                label: '记录人',
                value: 'diary_recorder_name_extra',
            },
            {
                label: '日志类型',
                value: 'diary_type',
            },
            {
                label: '地点',
                value: 'location',
            },
            {
                label: '医院',
                value: 'hospital_name',
            },
            {
                label: '工作事由',
                value: 'diary_content',
            },
            {
                label: '创建时间',
                value: 'create_date',
                width: '150'
            },
            
        ]
    },
    // 质检单
    reports: {
        currentSelect: [
            {
                value: 'reagent_name',
                name: '产品名称',
                type: 'select',
            }, {
                value: 'batch',
                name: '批号',
                type: 'select',
            },
        ],
        tooltipContent: '质检单',
        tableHeader: [
            {
                label: '产品名称',
                value: 'reagent_name',
            },
            {
                label: '批号',
                value: 'batch',
            },
            {
                label: '规格型号',
                value: 'format',
            },
        ]
    },
    // 质控
    qcs: {
        currentSelect: [{
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            value: 'testing_id',
            name: '检测项目',
            type: 'select'
        }, {
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'qc_batch',
            name: '质控批号',
            type: 'input'
        }, {
            value: 'kit_batch',
            name: '试剂批号',
            type: 'input'
        },],
        seniorSelect: [{
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '检测项目',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'testing_id'
        }, {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, {
            name: '质控批号',
            value: '',
            type: 'input',
            list: 'qc_batch'
        }, {
            name: '试剂批号',
            value: '',
            type: 'input',
            list: 'kit_batch'
        }, 
        {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }],
        tooltipContent: '质控',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_postion_name_extra',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150'
            },
            {
                label: '检测项目',
                value: 'testing_unique_extra',
            },
            {
                label: '试剂批号',
                value: 'kit_batch_extra',
            },
            {
                label: '质控结果',
                value: 'qc_result',
            },
            {
                label: '创建时间',
                value: 'yun_time',
                width: '150'
            },
            {
                label: '质控号',
                value: 'qc_number',
            },
            {
                label: '质控品批号',
                value: 'qc_batch',
            },
        ],
    },
    // 定标
    calibrations: {
        currentSelect: [{
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // },
         {
            value: 'testing_id',
            name: '检测项目',
            type: 'select'
        }],
        seniorSelect: [{
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '检测项目',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'testing_id'
        }, {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, 
        {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // },
        
        {
            name: '试剂批号',
            value: '',
            type: 'input',
            list: 'batch'
        },
        {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        },],
        tooltipContent: '定标',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_pos_name',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150'
            },
            {
                label: '检测项目',
                value: 'testing_unique_extra',
            },
            {
                label: '试剂批号',
                value: 'batch',
            },
            {
                label: '创建时间',
                value: 'time_extra',
                width: '150'
            },
        ],
    },
    // 告警
    recordwarnings: {
        currentSelect: [
            {
            value: 'hospital_id',
            name: '医院名称',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // },
        {
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, {
            value: 'warning_id',
            name: '告警代码',
            type: 'select',
        }, {
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'platform_id',
            name: '平台',
            type: 'select'
        }],
        seniorSelect: [{
            name: '平台',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'platform_id'
        }, {
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        },  {
            name: '市区',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'city_id'
        }, {
            name: '告警代码',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'warning_id'
        },
        {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // },
         {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, {
            name: '工程师',
            value: '',
            type: 'input',
            list: 'engineer_name'
        }, {
            name: '时间',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }],
        tooltipContent: '告警',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id',

            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_postion_name_extra',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150',
            },
            {
                label: '经销商',
                value: 'customer_name_extra',
            },
            {
                label: '售后人员',
                value: 'engineer_name_extra',
            },
            {
                label: '告警代码',
                value: 'warning_id',
            },
            {
                label: '告警内容',
                value: 'warning_name_extra',
            },
            {
                label: '创建时间',
                value: 'creat_time',
                width: '150'
            },
        ]
    },
    // 维修单
    repairtask: {
        currentSelect: [{
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'implement_engineer_name',
            name: '工程师',
            type: 'input'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // },
         {
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, {
            value: 'platform_id',
            name: '平台',
            type: 'select'
        }],
        seniorSelect: [{
            name: '平台',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'platform_id'
        }, {
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '工程师',
            value: '',
            type: 'input',
            list: 'implement_engineer_name'
        }, 
        {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // },
         {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }],
        tooltipContent: '维修单',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_postion_name_extra',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150',
            },
            {
                label: '经销商',
                value: 'customer_name_extra',
            },
            {
                label: '售后人员',
                value: 'implement_engineer_name',
            },
            {
                label: '故障类型',
                value: 'fault_type_extra',
            },
            {
                label: '维修时间',
                value: 'implement_date',
                width: '150',
            },
        ],
    },
    // 保养单
    maintaintask: {
        currentSelect: [{
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            value: 'implement_engineer_name',
            name: '工程师',
            type: 'input'
        }, {
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, {
            value: 'platform_id',
            name: '平台',
            type: 'select'
        }],
        seniorSelect: [{
            name: '平台',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'platform_id'
        }, {
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '工程师',
            value: '',
            type: 'input',
            list: 'implement_engineer_name'
        }, 
        {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, 
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }],
        tooltipContent: '保养单',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_postion_name_extra',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150',
            },
            {
                label: '经销商',
                value: 'customer_name_extra',
            },
            {
                label: '售后人员',
                value: 'implement_engineer_name',
            },
            {
                label: '保养时间',
                value: 'implement_date',
                width: '150',
            },
        ],
    },
    // 装机单
    installtask: {
        currentSelect: [{
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'implement_engineer_name',
            name: '工程师',
            type: 'input'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
        // {
        //     value: 'hospital_name',
        //     name: '医院',
        //     type: 'select',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, {
            value: 'platform_id',
            name: '平台',
            type: 'select'
        }],
        seniorSelect: [{
            name: '平台',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'platform_id'
        }, {
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '工程师',
            value: '',
            type: 'input',
            list: 'implement_engineer_name'
        },
        {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        },  
        // {
        //     name: '医院',
        //     value: '',
        //     type: 'select',
        //     list: 'hospital_name',
        //     remote: true,//是否开启远程搜索功能
        // }, 
        {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }],
        tooltipContent: '装机单',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_postion_name_extra',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150',
            },
            {
                label: '经销商',
                value: 'customer_name_extra',
            },
            {
                label: '售后人员',
                value: 'implement_engineer_name',
            },
            {
                label: '装机时间',
                value: 'apply_date',
                width: '150',
            },
        ],
    },
    // 物品申请
    articleapplis: {
        currentSelect: [{
            value: 'shipping_code',
            name: '发货单号',
            type: 'input'
        }, {
            value: 'apply_user',
            name: '申请人',
            type: 'input'
        },
        {
            value: 'organization_id',
            name: '收货单位',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        }],
        seniorSelect: [{
            name: '物品类型',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'article_type'
        }, {
            name: '收货单位',
            value: '',
            type: 'select',
            list: 'organization_id',
            remote: true,//是否开启远程搜索功能
        },{
            name: '申请人',
            value: '',
            type: 'input',
            list: 'apply_user'
        }, {
            name: '发货单号',
            value: '',
            type: 'input',
            list: 'shipping_code'
        }],
        tooltipContent: '物品申请',
        tableHeader: [
            {
                label: '申请编号',
                value: 'apply_id',
            },
            {
                label: '收货单位',
                value: 'organization_name',
            },
            {
                label: '申请人',
                value: 'apply_user_extra',
            },
            {
                label: '物品名称',
                value: 'article_name_extra',
            },
            {
                label: '申请状态',
                value: 'apply_status',
            },
            {
                label: '创建时间',
                value: 'create_time',
                width: '150'
            },
        ],
    },
    // 样本检测
    samplechecks: {
        currentSelect: [{
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        }, {
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'testing_id',
            name: '检测项目',
            type: 'select'
        }, {
            value: 'batch_id',
            name: '批号',
            type: 'input'
        }, {
            value: 'platform_id',
            name: '平台',
            type: 'select'
        }],
        seniorSelect: [{
            name: '平台',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'platform_id'
        }, {
            name: '检测项目',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'testing_id'
        }, {
            name: '阴阳性',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'is_positive'
        }, {
            name: '样本类型',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'sample_type_id'
        }, {
            name: '机号',
            value: '',
            type: 'input',
            list: 'instrument_id'
        },
        {
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        },  
        {
            name: 'UDI',
            value: '',
            type: 'input',
            list: 'bottle'
        }, {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, {
            name: '试剂批号',
            value: '',
            type: 'input',
            list: 'batch_id'
        }, {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        },],
        tooltipContent: '样本检测',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id_extra',
            },
            {
                label: '医院',
                value: 'hospital_name_extra',
            },
            {
                label: '仪器位置',
                value: 'hos_postion_name_extra',
            },
            {
                label: '平台',
                value: 'platform_unique_extra',
                width: '150',
            },
            {
                label: '检测项目',
                value: 'testing_unique_extra',
            },
            {
                label: '试剂批号',
                value: 'batch',
            },
            {
                label: '结果',
                value: 'sample_check_result',
            },
            {
                label: '阴阳性',
                value: 'is_positive',
            },
            {
                label: '样本类型',
                value: 'sample_type_name_extra',
            },
            {
                label: '创建时间',
                value: 'corrected_date',
                width: '150'
            },
        ],
    },
    // 失控
    cycle_qc_data_list: {
        currentSelect: [{
            value: 'province_id',
            name: '省份',
            type: 'select'
        }, 
        {
            value: 'hospital_id',
            name: '医院',
            type: 'select',
            remote: true,//是否开启远程搜索功能
        },
         {
            value: 'testing_id',
            name: '检测项目',
            type: 'select'
        }, {
            value: 'instrument_id',
            name: '机号',
            type: 'input'
        }, {
            value: 'qc_batch',
            name: '质控批号',
            type: 'input'
        }, {
            value: 'kit_batch',
            name: '试剂批号',
            type: 'input'
        },
        {
            value: 'qc_rule_c1',
            name: 'C1违背规则',
            type: 'select'
        },
        {
            value: 'qc_rule_c2',
            name: 'C2违背规则',
            type: 'select'
        },
        
    ],
        seniorSelect: [{
            name: '检测项目',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'testing_id'
        }, {
            name: 'UDI',
            value: '',
            type: 'input',
            list: 'instrument_id'
        }, {
            name: '医院',
            value: '',
            type: 'select',
            list: 'hospital_id',
            remote: true,//是否开启远程搜索功能
        }, {
            value: '',
            name: 'C1违背规则',
            type: 'select',
            list: 'qc_rule_c1'
        },  {
            value: '',
            name: 'C2违背规则',
            type: 'select',
            list: 'qc_rule_c2'
        },{
            name: '省份',
            value: '',
            type: 'select',
            selectOption: [],
            list: 'province_id'
        }, {
            name: '质控批号',
            value: '',
            type: 'input',
            list: 'qc_batch'
        }, {
            name: '试剂批号',
            value: '',
            type: 'input',
            list: 'kit_batch'
        }, {
            name: '日期范围',
            value: '',
            type: 'timeData',
            list: ['start_time', 'end_time']
        }],
        tooltipContent: '失控',
        tableHeader: [
            {
                label: '机号',
                value: 'instrument_id',
            },
            {
                label: '医院',
                value: 'hospital_full_name',
            },
            {
                label: '仪器位置',
                value: 'hos_pos_name',
            },
            {
                label: '平台',
                value: 'platform_unique',
                width: '150',
            },
            {
                label: '检测项目',
                value: 'testing_unique',
            },
            {
                label: '试剂批号',
                value: 'reagent_batch',
            },
            {
                label: '创建时间',
                value: 'create_date',
                width: '150'
            },
            {
                label: '违背规则',
                value: 'qc_info',
            },
            {
                label: '是否有失控报告',
                value: 'pdf_url',
            },
        ],
    }
}
